import localazyMetadata from '@Root/localazy-meta';

const i18nConfig = {
  locales: localazyMetadata.languages
    .map((lng) => lng.language)
    .sort((x, y) => {
      if (x === localazyMetadata.baseLocale) {
        return -1;
      }

      if (y === localazyMetadata.baseLocale) {
        return 1;
      }

      return 0;
    }),
  defaultLocale: 'en',
};

export default i18nConfig;
