export type LocalazyLanguage = {
    language: string;
    region: string;
    script: string;
    isRtl: boolean;
    localizedName: string;
    name: string;
    aliasOf?: string | null | undefined;
    expansionOf?: string | null | undefined;
    pluralType: (n: number) => "zero" | "one" | "two" | "many" | "few" | "other";
};
export type LocalazyFile = {
    cdnHash: string;
    file: string;
    path: string;
    library?: string | null | undefined;
    module?: string | null | undefined;
    buildType?: string | null | undefined;
    productFlavors?: string[] | null | undefined;
    cdnFiles: { [lang:string]: string };
};
export type LocalazyMetadata = {
    projectUrl: string;
    baseLocale: string;
    languages: LocalazyLanguage[];
    files: LocalazyFile[];
};
             
const localazyMetadata: LocalazyMetadata = {
  projectUrl: "https://localazy.com/p/10fastfingers",
  baseLocale: "en",
  languages: [
    {
      language: "ar",
      region: "",
      script: "",
      isRtl: true,
      name: "Arabic",
      localizedName: "العربية",
      pluralType: (n) => { return (n===0) ? "zero" : (n===1) ? "one" : (n===2) ? "two" : (n%100>=3 && n%100<=10) ? "few" : (n%100>=11 && n%100<=99) ? "many" : "other"; }
    },
    {
      language: "da",
      region: "",
      script: "",
      isRtl: false,
      name: "Danish",
      localizedName: "Dansk",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "de",
      region: "",
      script: "",
      isRtl: false,
      name: "German",
      localizedName: "Deutsch",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "en",
      region: "",
      script: "",
      isRtl: false,
      name: "English",
      localizedName: "English",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "es",
      region: "",
      script: "",
      isRtl: false,
      name: "Spanish",
      localizedName: "Español",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "fi",
      region: "",
      script: "",
      isRtl: false,
      name: "Finnish",
      localizedName: "Suomi",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "fr",
      region: "",
      script: "",
      isRtl: false,
      name: "French",
      localizedName: "Français",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "id",
      region: "",
      script: "",
      isRtl: false,
      name: "Indonesian",
      localizedName: "Indonesia",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "it",
      region: "",
      script: "",
      isRtl: false,
      name: "Italian",
      localizedName: "Italiano",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "ja",
      region: "",
      script: "",
      isRtl: false,
      name: "Japanese",
      localizedName: "日本語",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "ko",
      region: "",
      script: "",
      isRtl: false,
      name: "Korean",
      localizedName: "한국어",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "lt",
      region: "",
      script: "",
      isRtl: false,
      name: "Lithuanian",
      localizedName: "Lietuvių",
      pluralType: (n) => { return ((n%10===1) && ((n%100<11 || n%100>19))) ? "one" : ((n%10>=2 && n%10<=9) && ((n%100<11 || n%100>19))) ? "few" : "other"; }
    },
    {
      language: "nl",
      region: "",
      script: "",
      isRtl: false,
      name: "Dutch",
      localizedName: "Nederlands",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "pl",
      region: "",
      script: "",
      isRtl: false,
      name: "Polish",
      localizedName: "Polski",
      pluralType: (n) => { return (n===1) ? "one" : ((n%10>=2 && n%10<=4) && ((n%100<12 || n%100>14))) ? "few" : "many"; }
    },
    {
      language: "pt",
      region: "",
      script: "",
      isRtl: false,
      name: "Portuguese",
      localizedName: "Português",
      pluralType: (n) => { return (n>=0 && n<=1) ? "one" : "other"; }
    },
    {
      language: "ru",
      region: "",
      script: "",
      isRtl: false,
      name: "Russian",
      localizedName: "Русский",
      pluralType: (n) => { return ((n%10===1) && (n%100!==11)) ? "one" : ((n%10>=2 && n%10<=4) && ((n%100<12 || n%100>14))) ? "few" : "many"; }
    },
    {
      language: "sk",
      region: "",
      script: "",
      isRtl: false,
      name: "Slovak",
      localizedName: "Slovenčina",
      pluralType: (n) => { return (n===1) ? "one" : (n>=2 && n<=4) ? "few" : "other"; }
    },
    {
      language: "sw",
      region: "",
      script: "",
      isRtl: false,
      name: "Swahili",
      localizedName: "Kiswahili",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "th",
      region: "",
      script: "",
      isRtl: false,
      name: "Thai",
      localizedName: "ไทย",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "tr",
      region: "",
      script: "",
      isRtl: false,
      name: "Turkish",
      localizedName: "Türkçe",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "uk",
      region: "",
      script: "",
      isRtl: false,
      name: "Ukrainian",
      localizedName: "Українська",
      pluralType: (n) => { return ((n%10===1) && (n%100!==11)) ? "one" : ((n%10>=2 && n%10<=4) && ((n%100<12 || n%100>14))) ? "few" : "many"; }
    },
    {
      language: "vi",
      region: "",
      script: "",
      isRtl: false,
      name: "Vietnamese",
      localizedName: "Tiếng Việt",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "zh",
      region: "CN",
      script: "Hans",
      isRtl: false,
      name: "Chinese (China, Simplified)",
      localizedName: "中文 (中国, 简体)",
      pluralType: (n) => { return "other"; }
    }
  ],
  files: [
    {
      cdnHash: "e279be24695ec128af4c7f77bc1c668c9d773df8",
      file: "login.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/ar/login.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/de/login.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/en/login.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/es/login.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/fr/login.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/id/login.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/nl/login.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/pt/login.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/ru/login.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/sk/login.json",
        "th": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/th/login.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/tr/login.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e279be24695ec128af4c7f77bc1c668c9d773df8/vi/login.json"
      }
    },
    {
      cdnHash: "5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f",
      file: "terms.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/ar/terms.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/de/terms.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/en/terms.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/es/terms.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/fr/terms.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/id/terms.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/nl/terms.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/pt/terms.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/ru/terms.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/sk/terms.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/tr/terms.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/5b7a9d5bbe8bc2188eb61352da2e0da7e94bc31f/vi/terms.json"
      }
    },
    {
      cdnHash: "e289c16d90c764d5ca48a476774b4d86e1599f3f",
      file: "competition.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/ar/competition.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/de/competition.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/en/competition.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/es/competition.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/fi/competition.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/fr/competition.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/id/competition.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/pt/competition.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/ru/competition.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/sk/competition.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/tr/competition.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/uk/competition.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e289c16d90c764d5ca48a476774b4d86e1599f3f/vi/competition.json"
      }
    },
    {
      cdnHash: "6dcbf95ac6a0820053e6d8dd91ec5e2300740cee",
      file: "error.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/ar/error.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/de/error.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/en/error.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/es/error.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/fr/error.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/id/error.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/nl/error.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/pt/error.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/ru/error.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/sk/error.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/tr/error.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/uk/error.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dcbf95ac6a0820053e6d8dd91ec5e2300740cee/vi/error.json"
      }
    },
    {
      cdnHash: "f0396b91f6598c6ea5d6cb5803e98e78b908acfa",
      file: "validators.json",
      path: "api/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/ar/validators.json",
        "da": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/da/validators.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/de/validators.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/en/validators.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/es/validators.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/fr/validators.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/id/validators.json",
        "it": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/it/validators.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/nl/validators.json",
        "pl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/pl/validators.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/pt/validators.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/ru/validators.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/sk/validators.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/tr/validators.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/uk/validators.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/vi/validators.json",
        "zh_CN#Hans": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f0396b91f6598c6ea5d6cb5803e98e78b908acfa/zh-Hans-CN/validators.json"
      }
    },
    {
      cdnHash: "4323b581da0e62919941bc4ae90595d1dc6fc3a4",
      file: "reset-password.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/ar/reset-password.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/de/reset-password.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/en/reset-password.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/es/reset-password.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/fi/reset-password.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/fr/reset-password.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/id/reset-password.json",
        "ja": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/ja/reset-password.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/pt/reset-password.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/ru/reset-password.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/sk/reset-password.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/tr/reset-password.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/uk/reset-password.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/4323b581da0e62919941bc4ae90595d1dc6fc3a4/vi/reset-password.json"
      }
    },
    {
      cdnHash: "27123f518bdb770fb6ecf1b627b6b59fceb03dfc",
      file: "cookie.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/ar/cookie.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/de/cookie.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/en/cookie.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/fr/cookie.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/id/cookie.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/nl/cookie.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/pt/cookie.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/ru/cookie.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/sk/cookie.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/tr/cookie.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/27123f518bdb770fb6ecf1b627b6b59fceb03dfc/vi/cookie.json"
      }
    },
    {
      cdnHash: "eeb5888d7c678812761456e5ddcc3a8206377d5d",
      file: "user.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/ar/user.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/de/user.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/en/user.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/es/user.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/fi/user.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/fr/user.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/id/user.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/pt/user.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/ru/user.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/sk/user.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/tr/user.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/uk/user.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/eeb5888d7c678812761456e5ddcc3a8206377d5d/vi/user.json"
      }
    },
    {
      cdnHash: "abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4",
      file: "text-practice.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/ar/text-practice.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/de/text-practice.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/en/text-practice.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/es/text-practice.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/fi/text-practice.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/fr/text-practice.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/id/text-practice.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/pt/text-practice.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/ru/text-practice.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/sk/text-practice.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/tr/text-practice.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/uk/text-practice.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/abd3e3e01f4fe1d50d8f94bf600bc948d1a762f4/vi/text-practice.json"
      }
    },
    {
      cdnHash: "7174a8ac151b350334b663ffc4f52bd614fb37a6",
      file: "contact.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/ar/contact.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/de/contact.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/en/contact.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/fr/contact.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/id/contact.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/nl/contact.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/pt/contact.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/ru/contact.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/sk/contact.json",
        "th": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/th/contact.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/tr/contact.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/7174a8ac151b350334b663ffc4f52bd614fb37a6/vi/contact.json"
      }
    },
    {
      cdnHash: "fecd019945c5af10661f4eb36167f5031274bd10",
      file: "translate.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/ar/translate.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/de/translate.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/en/translate.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/es/translate.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/fr/translate.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/id/translate.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/nl/translate.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/pt/translate.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/ru/translate.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/sk/translate.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/tr/translate.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/fecd019945c5af10661f4eb36167f5031274bd10/vi/translate.json"
      }
    },
    {
      cdnHash: "28021f858fa72a13551b192cd45e932d124d9a1f",
      file: "result.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/ar/result.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/de/result.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/en/result.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/fr/result.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/id/result.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/pt/result.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/ru/result.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/sk/result.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/tr/result.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/uk/result.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/28021f858fa72a13551b192cd45e932d124d9a1f/vi/result.json"
      }
    },
    {
      cdnHash: "0cf6d2015baae560c02db77396c610c79e691ca9",
      file: "email.json",
      path: "api/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/ar/email.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/de/email.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/en/email.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/es/email.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/fi/email.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/fr/email.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/id/email.json",
        "ja": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/ja/email.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/pt/email.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/ru/email.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/sk/email.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/tr/email.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/uk/email.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/0cf6d2015baae560c02db77396c610c79e691ca9/vi/email.json"
      }
    },
    {
      cdnHash: "a4010b544e4e18c76bdd9d00f607fa4e58e1a580",
      file: "index.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/ar/index.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/de/index.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/en/index.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/fr/index.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/id/index.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/nl/index.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/pt/index.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/ru/index.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/sk/index.json",
        "th": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/th/index.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/tr/index.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/uk/index.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a4010b544e4e18c76bdd9d00f607fa4e58e1a580/vi/index.json"
      }
    },
    {
      cdnHash: "56c08f07d41c42bbb73840609cd8d10d00dc2ede",
      file: "custom-typing-test.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/ar/custom-typing-test.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/de/custom-typing-test.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/en/custom-typing-test.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/fi/custom-typing-test.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/fr/custom-typing-test.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/id/custom-typing-test.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/pt/custom-typing-test.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/ru/custom-typing-test.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/sk/custom-typing-test.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/tr/custom-typing-test.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/uk/custom-typing-test.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/56c08f07d41c42bbb73840609cd8d10d00dc2ede/vi/custom-typing-test.json"
      }
    },
    {
      cdnHash: "9e010a1e46b174dce53981b1b3944c95d3c9dc2d",
      file: "security.json",
      path: "api/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/ar/security.json",
        "da": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/da/security.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/de/security.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/en/security.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/es/security.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/fr/security.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/id/security.json",
        "it": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/it/security.json",
        "ko": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/ko/security.json",
        "lt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/lt/security.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/nl/security.json",
        "pl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/pl/security.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/pt/security.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/ru/security.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/sk/security.json",
        "sw": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/sw/security.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/tr/security.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/uk/security.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/vi/security.json",
        "zh_CN#Hans": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9e010a1e46b174dce53981b1b3944c95d3c9dc2d/zh-Hans-CN/security.json"
      }
    },
    {
      cdnHash: "f83b652d7b9281ae16e6f9736524b89fabe5ddc9",
      file: "partners.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/ar/partners.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/de/partners.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/en/partners.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/fr/partners.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/id/partners.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/nl/partners.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/pt/partners.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/ru/partners.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/sk/partners.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/tr/partners.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f83b652d7b9281ae16e6f9736524b89fabe5ddc9/vi/partners.json"
      }
    },
    {
      cdnHash: "d1085cb46037c4ca2f924ec7777acd398f5434e1",
      file: "faq.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/ar/faq.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/de/faq.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/en/faq.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/fr/faq.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/id/faq.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/nl/faq.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/pt/faq.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/ru/faq.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/sk/faq.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/tr/faq.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/d1085cb46037c4ca2f924ec7777acd398f5434e1/vi/faq.json"
      }
    },
    {
      cdnHash: "e314cb42ff366612d53a49c75aa4a0a4c3ac3088",
      file: "account.json",
      path: "api/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/ar/account.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/de/account.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/en/account.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/fi/account.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/fr/account.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/id/account.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/pt/account.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/ru/account.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/sk/account.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/tr/account.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/uk/account.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e314cb42ff366612d53a49c75aa4a0a4c3ac3088/vi/account.json"
      }
    },
    {
      cdnHash: "11b7c60c4b2f51b00c3944b44f2183ba347535ff",
      file: "global.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/ar/global.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/de/global.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/en/global.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/es/global.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/fi/global.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/fr/global.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/id/global.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/nl/global.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/pt/global.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/ru/global.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/sk/global.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/tr/global.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/uk/global.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/11b7c60c4b2f51b00c3944b44f2183ba347535ff/vi/global.json"
      }
    },
    {
      cdnHash: "10558be3961283d146978607a4e34328375ce734",
      file: "settings.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/ar/settings.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/de/settings.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/en/settings.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/es/settings.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/fi/settings.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/fr/settings.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/id/settings.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/pt/settings.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/ru/settings.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/sk/settings.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/tr/settings.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/uk/settings.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/10558be3961283d146978607a4e34328375ce734/vi/settings.json"
      }
    },
    {
      cdnHash: "706a16cf9986a66fb186acd45b75da8b2285e274",
      file: "multiplayer.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/ar/multiplayer.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/de/multiplayer.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/en/multiplayer.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/es/multiplayer.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/fr/multiplayer.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/id/multiplayer.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/nl/multiplayer.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/pt/multiplayer.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/ru/multiplayer.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/sk/multiplayer.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/tr/multiplayer.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/706a16cf9986a66fb186acd45b75da8b2285e274/vi/multiplayer.json"
      }
    },
    {
      cdnHash: "b6398b151e171c6d4b6c821b833c67df54e8a17d",
      file: "share.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/ar/share.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/de/share.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/en/share.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/fr/share.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/id/share.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/pt/share.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/ru/share.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/sk/share.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/tr/share.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b6398b151e171c6d4b6c821b833c67df54e8a17d/vi/share.json"
      }
    },
    {
      cdnHash: "61e4198573636bc75478545275238d9567c1f670",
      file: "image.json",
      path: "api/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/61e4198573636bc75478545275238d9567c1f670/ar/image.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/61e4198573636bc75478545275238d9567c1f670/de/image.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/61e4198573636bc75478545275238d9567c1f670/en/image.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/61e4198573636bc75478545275238d9567c1f670/fr/image.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/61e4198573636bc75478545275238d9567c1f670/id/image.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/61e4198573636bc75478545275238d9567c1f670/ru/image.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/61e4198573636bc75478545275238d9567c1f670/sk/image.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/61e4198573636bc75478545275238d9567c1f670/tr/image.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/61e4198573636bc75478545275238d9567c1f670/vi/image.json"
      }
    },
    {
      cdnHash: "36bc0a5a1accc29a09e032d1f9441d862b4bfa25",
      file: "gdpr.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/ar/gdpr.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/de/gdpr.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/en/gdpr.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/fr/gdpr.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/id/gdpr.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/nl/gdpr.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/ru/gdpr.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/sk/gdpr.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/tr/gdpr.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/36bc0a5a1accc29a09e032d1f9441d862b4bfa25/vi/gdpr.json"
      }
    },
    {
      cdnHash: "aea7b7d6539563ada21ae2d25e80a2b113e83a2e",
      file: "press.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/ar/press.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/de/press.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/en/press.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/fr/press.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/id/press.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/nl/press.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/ru/press.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/sk/press.json",
        "th": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/th/press.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/tr/press.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/aea7b7d6539563ada21ae2d25e80a2b113e83a2e/vi/press.json"
      }
    },
    {
      cdnHash: "9c30073e25d7b7829e1402a5f2ff1e83c8b25724",
      file: "achievements.json",
      path: "app/public/translations",
      cdnFiles: {
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/9c30073e25d7b7829e1402a5f2ff1e83c8b25724/en/achievements.json"
      }
    },
    {
      cdnHash: "e6e1b564b45f6319e3ebf9832b1c0995fce9d385",
      file: "signup.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/ar/signup.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/de/signup.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/en/signup.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/es/signup.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/fr/signup.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/id/signup.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/nl/signup.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/pt/signup.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/ru/signup.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/sk/signup.json",
        "th": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/th/signup.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/tr/signup.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/e6e1b564b45f6319e3ebf9832b1c0995fce9d385/vi/signup.json"
      }
    },
    {
      cdnHash: "ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a",
      file: "theme.json",
      path: "api/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/ar/theme.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/de/theme.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/en/theme.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/fr/theme.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/id/theme.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/nl/theme.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/pt/theme.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/ru/theme.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/sk/theme.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/tr/theme.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/ee3603b1b2c70aa96a44b5d097fe7c31df4b6b9a/vi/theme.json"
      }
    },
    {
      cdnHash: "657e65bf3e2946e2d849a78b860d473af88ffa38",
      file: "recover-password.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/ar/recover-password.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/de/recover-password.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/en/recover-password.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/es/recover-password.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/fr/recover-password.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/id/recover-password.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/pt/recover-password.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/ru/recover-password.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/sk/recover-password.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/tr/recover-password.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/657e65bf3e2946e2d849a78b860d473af88ffa38/vi/recover-password.json"
      }
    },
    {
      cdnHash: "6dc0ff44b5c8236810a6bd9063d08960aa56127c",
      file: "game-mode.json",
      path: "api/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dc0ff44b5c8236810a6bd9063d08960aa56127c/ar/game-mode.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dc0ff44b5c8236810a6bd9063d08960aa56127c/de/game-mode.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dc0ff44b5c8236810a6bd9063d08960aa56127c/en/game-mode.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dc0ff44b5c8236810a6bd9063d08960aa56127c/fr/game-mode.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dc0ff44b5c8236810a6bd9063d08960aa56127c/id/game-mode.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dc0ff44b5c8236810a6bd9063d08960aa56127c/ru/game-mode.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dc0ff44b5c8236810a6bd9063d08960aa56127c/sk/game-mode.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dc0ff44b5c8236810a6bd9063d08960aa56127c/tr/game-mode.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/6dc0ff44b5c8236810a6bd9063d08960aa56127c/vi/game-mode.json"
      }
    },
    {
      cdnHash: "b2ea742babc66132d0d2c1babfbdc5290c45bee9",
      file: "imprint.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/ar/imprint.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/de/imprint.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/en/imprint.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/fr/imprint.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/id/imprint.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/nl/imprint.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/ru/imprint.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/sk/imprint.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/tr/imprint.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2ea742babc66132d0d2c1babfbdc5290c45bee9/vi/imprint.json"
      }
    },
    {
      cdnHash: "37f1388f0b0489ba3f882e7bf18a32e50ff20895",
      file: "about.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/ar/about.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/de/about.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/en/about.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/es/about.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/fi/about.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/fr/about.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/id/about.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/nl/about.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/pt/about.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/ru/about.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/sk/about.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/tr/about.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/uk/about.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/37f1388f0b0489ba3f882e7bf18a32e50ff20895/vi/about.json"
      }
    },
    {
      cdnHash: "a9dd7ee91166a14b9b668df27477ad1170f2bec6",
      file: "achievements.json",
      path: "api/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/ar/achievements.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/de/achievements.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/en/achievements.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/fr/achievements.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/id/achievements.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/nl/achievements.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/ru/achievements.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/sk/achievements.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/tr/achievements.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/a9dd7ee91166a14b9b668df27477ad1170f2bec6/vi/achievements.json"
      }
    },
    {
      cdnHash: "633b5eb219304e797e0a1f54b013ca63dea93c6c",
      file: "typing-box.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/ar/typing-box.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/de/typing-box.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/en/typing-box.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/fr/typing-box.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/id/typing-box.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/pt/typing-box.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/ru/typing-box.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/sk/typing-box.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/tr/typing-box.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/633b5eb219304e797e0a1f54b013ca63dea93c6c/vi/typing-box.json"
      }
    },
    {
      cdnHash: "45264cb84c8ceba81bd907d158d6c7b535ef076e",
      file: "blog.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/ar/blog.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/de/blog.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/en/blog.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/fr/blog.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/id/blog.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/nl/blog.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/pt/blog.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/ru/blog.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/sk/blog.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/tr/blog.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/45264cb84c8ceba81bd907d158d6c7b535ef076e/vi/blog.json"
      }
    },
    {
      cdnHash: "b2f8b643efce1d184bb6d35928af07a7fbf8f2bc",
      file: "privacy.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/ar/privacy.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/de/privacy.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/en/privacy.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/fr/privacy.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/id/privacy.json",
        "nl": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/nl/privacy.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/pt/privacy.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/ru/privacy.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/sk/privacy.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/tr/privacy.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/b2f8b643efce1d184bb6d35928af07a7fbf8f2bc/vi/privacy.json"
      }
    },
    {
      cdnHash: "f9f3cb5d661bb7148442518213d19d524fcd279e",
      file: "typing-test.json",
      path: "app/public/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/ar/typing-test.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/de/typing-test.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/en/typing-test.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/es/typing-test.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/fi/typing-test.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/fr/typing-test.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/id/typing-test.json",
        "ja": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/ja/typing-test.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/pt/typing-test.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/ru/typing-test.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/sk/typing-test.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/tr/typing-test.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/uk/typing-test.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/f9f3cb5d661bb7148442518213d19d524fcd279e/vi/typing-test.json"
      }
    },
    {
      cdnHash: "c7691f12625d5ed74bc9f8a15db7566e8acd2494",
      file: "notification.json",
      path: "api/translations",
      cdnFiles: {
        "ar": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/ar/notification.json",
        "de": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/de/notification.json",
        "en": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/en/notification.json",
        "es": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/es/notification.json",
        "fi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/fi/notification.json",
        "fr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/fr/notification.json",
        "id": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/id/notification.json",
        "pt": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/pt/notification.json",
        "ru": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/ru/notification.json",
        "sk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/sk/notification.json",
        "tr": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/tr/notification.json",
        "uk": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/uk/notification.json",
        "vi": "https://delivery.localazy.com/_a87446808326427429436ca9f1fe/_e0/c7691f12625d5ed74bc9f8a15db7566e8acd2494/vi/notification.json"
      }
    }
  ]
};

export default localazyMetadata;